<template>
  <div class="wscn-httpwait-container">
    <div class="wscn-httpwait">
      <div class="pic-wait" style="width: 48%;">
        <img class="pic-wait__parent" src="@/assets/queue_images/queuing.gif" alt />
      </div>
      <div class="bullshit" style="width: 65%;text-align: center;">
        <div class="bullshit__headline" style="color: red;">{{ message }}</div>
        <el-button type="primary" @click="reload" round>刷新</el-button>
        <!-- <a href="location.reload()" class="bullshit__return-home" style="margin-left: 45%;margin-top: 30px;">刷新</a> -->
        <!-- <a href="javascript:history.back(-1)" class="bullshit__return-home" style="margin-left: 45%;margin-top: 30px;">Back to home</a> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'queuePage',

  data () {
    return {
      timer: null
    }
  },
  computed: {
    message () {
      return '当前访问人数较多，系统正在加急处理中，请稍后'
    }
  },
  created () {
    this.timer = setTimeout(() => {
      this.reload() // 30s后自动返回上一页面
    }, 30 * 1000)
  },
  mounted () {
  },
  beforeDestroy () { // 离开当前页面销毁定时器初始化时重新计时
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
  methods: {
    reload () {
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
.wscn-httpwait-container {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.wscn-httpwait {
  position: relative;
  width: 1200px;
  // padding: 0 50px;
  overflow: hidden;
  // left: calc((100% - 973px) / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .pic-wait {
    position: relative;
    float: left;
    width: 600px;
    overflow: hidden;

    &__parent {
      width: 100%;
    }

    &__child {
      position: absolute;

      &.left {
        width: 80px;
        top: 17px;
        left: 220px;
        opacity: 0;
        animation-name: cloudLeft;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }

      &.mid {
        width: 46px;
        top: 10px;
        left: 420px;
        opacity: 0;
        animation-name: cloudMid;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1.2s;
      }

      &.right {
        width: 62px;
        top: 100px;
        left: 500px;
        opacity: 0;
        animation-name: cloudRight;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }

      @keyframes cloudLeft {
        0% {
          top: 17px;
          left: 220px;
          opacity: 0;
        }

        20% {
          top: 33px;
          left: 188px;
          opacity: 1;
        }

        80% {
          top: 81px;
          left: 92px;
          opacity: 1;
        }

        100% {
          top: 97px;
          left: 60px;
          opacity: 0;
        }
      }

      @keyframes cloudMid {
        0% {
          top: 10px;
          left: 420px;
          opacity: 0;
        }

        20% {
          top: 40px;
          left: 360px;
          opacity: 1;
        }

        70% {
          top: 130px;
          left: 180px;
          opacity: 1;
        }

        100% {
          top: 160px;
          left: 120px;
          opacity: 0;
        }
      }

      @keyframes cloudRight {
        0% {
          top: 100px;
          left: 500px;
          opacity: 0;
        }

        20% {
          top: 120px;
          left: 460px;
          opacity: 1;
        }

        80% {
          top: 180px;
          left: 340px;
          opacity: 1;
        }

        100% {
          top: 200px;
          left: 300px;
          opacity: 0;
        }
      }
    }
  }

  .bullshit {
    position: relative;
    float: left;
    width: 300px;
    padding: 40px 0;
    overflow: hidden;

    &__oops {
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      color: #1482f0;
      opacity: 0;
      margin-bottom: 20px;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }

    &__headline {
      font-size: 20px;
      line-height: 24px;
      color: #222;
      font-weight: bold;
      opacity: 0;
      margin-bottom: 20px;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.1s;
      animation-fill-mode: forwards;
    }

    &__info {
      font-size: 13px;
      line-height: 21px;
      color: grey;
      opacity: 0;
      margin-bottom: 30px;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.2s;
      animation-fill-mode: forwards;
    }

    &__return-home {
      display: block;
      float: left;
      width: 110px;
      height: 36px;
      background: #1482f0;
      border-radius: 100px;
      text-align: center;
      color: #ffffff;
      opacity: 0;
      font-size: 14px;
      line-height: 36px;
      cursor: pointer;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
    }

    @keyframes slideUp {
      0% {
        transform: translateY(60px);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  ::v-deep #app {
    background: #ffffff !important;
  }
}

.bullshit__headline::after {
  content: '';
  animation: dots 3s infinite step-start;
}

@keyframes dots {
  16.66% {
    content: '.';
  }
  33.33% {
    content: '..';
  }
  50% {
    content: '...';
  }
  66.66% {
    content: '....';
  }
  83.33% {
    content: '.....';
  }
  100% {
    content: '......';
  }
}
</style>
